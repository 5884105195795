export default {
  en: {
    actionUnavailable: 'Action unavailable',
    actionUnavailableMsg: 'We are currently working to resolve this issue.',
    available: 'Available',
    backButton: `BACK`,
    backToPrevious: 'Back to previous',
    ccs: 'CCS',
    cHAdeMO: 'CHAdeMO',
    chargersAtStation: `Chargers at this station`,
    charging: 'Charging',
    clearSearch: 'Clear search',
    closeFilter: 'Close Filter',
    closeNearbyChargersList: 'Close nearby chargers list',
    createAccount: `Create an account within the Electrify Canada app to see charger availability`,
    comingSoon: 'Coming Soon',
    comingSoonPinAltText: 'coming soon location pin',
    comingSoonStation: 'This station is coming soon',
    commercial: 'Commercial',
    commercialStation: 'This is a Commercial station',
    connectorType: 'Connector Type',
    directions: 'Directions',
    downloadTheApp: 'Download the app for real-time charger availability',
    downloadTheApp2: 'Download the app',
    expandResultList: 'Expand result list',
    expandStationList: 'Expand station list',
    fastChargingStation: 'Fast charging station',
    fastChargingStationPinAltText: 'Fast charging location pin',
    filter: 'Filter',
    filters: 'Filter',
    for: 'for',
    headline: 'Locate a charger',
    hyperFast: `Hyper-Fast`,
    idleFee: '$0.40 / min.',
    idleFeeNotice: 'idle fee applied after a 10 minute grace period.',
    l2Stations: 'This is an L2 station',
    level2: 'Level2',
    listView: 'List View',
    locateACharger: 'Locate a Charger',
    locations: 'Locations',
    locationsPage: {
      locationsHeading: 'EV Charging Stations in Canada',
      provinceHeading: 'EV Charging Station in ',
      provinceHeadingPlural: 'EV Charging Stations in ',
      cityHeading: 'EV Charging Station in ',
      cityHeadingPlural: 'EV Charging Stations in ',
    },
    meta: {
      title: 'Locate a public EV charger',
      description:
        'Connect to our network of public electric vehicle (EV) chargers by finding the Electrify Canada charger closest to you.',
      canonicalUrl: 'https://www.electrify-canada.ca/locate-charger/',
      locationTitle: 'Electrify Canada  in',
      locationDescription: 'Find an Electrify Canada charging station at',
    },
    noChargersInView: 'No chargers in view',
    noSessionFee: 'No session fee',
    noStationsInView: 'No stations in view',
    oops: 'Oops!',
    oopsErrorMsg: 'Something unexpected occurred. Please refresh your browser and try again.',
    openDirections: 'Open directions in Google Maps',
    openStation: 'This is an open station',
    plannedStations:
      'These are planned charging station locations. Completed locations are subject to change. Please check back on this site for future updates.',
    power: 'Power',
    pricesEffectiveDate: 'Prices effective October 5, 2020',
    pricing: `PRICING`,
    pricingMessage: ` Pricing for DC fast charging is determined by charger location and your plan. Real-time pricing is available in the app or at the charger.`,
    pricingLearnMore: `Learn more about pricing information`,
    realTimeAvailability: 'REAL-TIME CHARGER AVAILABILITY ONLY IN THE APP',
    realTimeInTheApp: 'Create an account or sign-in within the Electrify Canada app to see charger availability',
    recentlyViewed: `recently viewed`,
    scrollToTop: 'Scroll to top',
    search: 'Search for an address or location',
    searchResults: 'Search Results',
    searchResultsNotFound: 'No results for "{value}." Try another search.',
    skipToSearch: 'Skip to search',
    skipToSearchContinued: 'for an address or location',
    station: 'Station',
    stations: 'Stations',
    stationsList: 'Station List',
    status: 'Status',
    ultraFast: `Ultra-Fast`,
    viewAllNearby: 'View all nearby',
    viewPricing: 'View up-to-date pricing and real-time charger availability in the mobile app',
    whatConnectorType: `What connector type does my vehicle use?`,
    whichConnector: 'Which connector should I use?',
    zoomIn: 'Zoom in',
    zoomInAltText: 'plus sign to enlarge map view',
    zoomOut: 'Zoom out',
    zoomOutAltText: 'minus sign to reduce map view',
  },
  fr: {
    actionUnavailable: 'Action non disponible',
    actionUnavailableMsg: `Nous travaillons actuellement à résoudre ce problème.`,
    available: 'La borne est active',
    backButton: `RETOUR`,
    backToPrevious: 'Retour à la précédente',
    ccs: 'CCS',
    cHAdeMO: 'CHAdeMO',
    chargersAtStation: `BORNES DE RECHARGE ICI`,
    charging: 'Coût',
    clearSearch: 'Effacer la recherche',
    closeFilter: 'Fermer le filtre',
    closeNearbyChargersList: 'Fermer la liste des bornes proches',
    createAccount: `Créez un compte dans l'appli Electrify Canada pour voir la disponibilité des bornes`,
    comingSoon: 'Site futur prévu',
    comingSoonStation: 'Cette station arrive bientôt',
    comingSoonPinAltText: 'punaise de localisation à venir bientôt',
    commercial: 'Commercial',
    commercialStation: 'Ceci est une station commerciale',
    connectorType: 'TYPE DE CONNECTEUR',
    directions: 'Itinéraire',
    downloadTheApp: `TÉLÉCHARGEZ L'APPLICATION POUR LA DISPONIBILITÉ des bornes EN TEMPS RÉEL`,
    downloadTheApp2: 'Télécharger l’application',
    expandResultList: 'Agrandir la liste des résultats',
    expandStationList: 'Agrandir la liste des stations',
    fastChargingStation: 'Station de recharge rapide',
    fastChargingStationPinAltText: 'broche de localisation station de recharge rapide',
    filter: 'Filtrer',
    filters: 'Filtres',
    for: 'pour',
    headline: 'Localiser une borne de recharge',
    hyperFast: `Hyper rapide`,
    idleFee: '0,40 $/min.',
    idleFeeNotice: "taxe d'attente appliquée après une période de grâce de 10 minutes.",
    l2Stations: 'Ceci est une station L2',
    level2: 'Level2',
    listView: 'Affichage en liste',
    locateACharger: 'Localiser une borne de recharge',
    locations: 'Lieux',
    locationsPage: {
      locationsHeading: 'Stations pour VE au Canada',
      provinceHeading: 'Station de recharge pour VE {in} ',
      provinceHeadingPlural: 'Stations de recharge pour VE {in} ',
      cityHeading: 'Station de recharge pour VE à ',
      cityHeadingPlural: 'Stations de recharge pour VE à ',
    },
    meta: {
      title: 'Localiser une borne public de VE',
      description:
        'Connectez-vous à notre réseau public des bornes de véhicules électriques (VE) en identifiant la borne Electrify Canada le plus près de chez vous.',
      canonicalUrl: 'https://www.electrify-canada.ca/fr/trouver-une-borne/',
      locationTitle: 'Electrify Canada à',
      locationDescription: 'Trouvez une borne de recharge Electrify Canada au',
    },
    monthlyFees: `plus les frais mensuels de 4 $`,
    noChargersInView: 'Aucune borne de recharge dans la vue',
    noSessionFee: 'Aucuns frais de session',
    noStationsInView: 'Aucune station en vue',
    oops: 'Oups!',
    oopsErrorMsg: `Quelque chose d'inattendu s’est produit. Veuillez actualiser votre navigateur et réessayer.`,
    openDirections: 'Ouvrir les directions dans Google Maps',
    openStation: 'Ceci est une station ouverte',
    plannedStations:
      "Il s'agit d'emplacements de stations de charge prévus. Les emplacements finalisés font l'objet de changements. Veuillez revenir sur ce site pour les mises à jour futures.",
    power: "D'énergie",
    pricesEffectiveDate: 'Prix en vigueur à compter du 5 octobre 2020',
    pricing: `TARIFICATION`,
    pricingMessage: `Les tarifs pour la recharge rapide en CC sont établis en fonction de l’emplacement de la borne de recharge et de votre forfait. La tarification en temps réel peut être obtenue dans l’application ou à la borne de recharge.`,
    pricingLearnMore: `En savoir plus sur la tarification`,
    realTimeAvailability: 'DISPONIBILITÉ DES BORNES EN TEMPS RÉEL UNIQUEMENT DANS L’APPLICATION',
    realTimeInTheApp:
      'Créez un compte ou connectez-vous dans l’application Electrify Canada pour voir la disponibilité des bornes de recharge',
    recentlyViewed: `RÉCEMMENT CONSULTÉS`,
    scrollToTop: 'Faire défiler vers le haut',
    search: 'Rechercher une adresse ou un lieu',
    searchResults: 'Résultats de la recherche',
    searchResultsNotFound: 'Aucun résultat de recherche trouvé pour "{value}." Essayez une autre recherche.',
    skipToSearch: 'Passer à la recherche',
    skipToSearchContinued: "d'une adresse ou d'un emplacement",
    station: 'Borne',
    stations: 'Bornes',
    stationsList: 'Liste des stations',
    status: 'Statut',
    ultraFast: `Ultrarapide`,
    viewAllNearby: 'Voir tous les proches',
    viewPricing: 'Consultez les prix à jour et la disponibilité d’une borne en temps réel dans l’application mobile',
    whatConnectorType: `Quel type de connecteur mon véhicule utilise-t-il?`,
    whichConnector: 'Quel connecteur dois-je utiliser?',
    zoomIn: 'Zoom avant',
    zoomInAltText: 'signe plus pour agrandir la vue de la carte',
    zoomOut: 'Zoom arrière',
    zoomOutAltText: 'signe moins pour réduire la vue de la carte',
  },
};
